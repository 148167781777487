$fa-font-path: "../fonts";
$font-family-sans-serif: 'Fira Sans', sans-serif;
$font-family-condensed: 'Fira Sans Extra Condensed', sans-serif;
$headings-font-weight: 900;

$red: #D71920;

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 3;
$grid-gutter-width: 32px;