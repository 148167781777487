#hero,
#mast { 
	position: relative; 
	background-color: transparent; 
	color: #ffffff; 
	overflow: hidden; 
	border-bottom: .25rem solid $red;
	h1 {
		color: #fff;
		font-size: 5rem;
	}
	a {
		color: #eee;
		&:hover {
			color: #fff;
		}
		
	}
}
#hero {
	.overlay {
		padding: 14rem 0 12rem 0;	
	}
	@include media-breakpoint-up(xs) {
		.overlay {
			padding: 5.5rem 0 4rem 0;
		}
		h1 {
			font-size: 3rem;
		}
	}
	@include media-breakpoint-up(sm) {
		.overlay {
			padding: 8.5rem 0 7rem 0;
		}
		h1 {
			font-size: 3.75rem;
		}
	}
	@include media-breakpoint-up(md) {
		.overlay {
			padding: 10.5rem 0 9rem 0;
		}
		h1 {
			font-size: 4.5rem;
		}
	}
	@include media-breakpoint-up(lg) {
		.overlay {
			padding: 11.5rem 0 10rem 0;
		}		
		h1 {
			font-size: 5.25rem;
		}
	}
}
#mast {
	@include media-breakpoint-up(xs) {
		.overlay {
			padding: 5rem 0 3rem 0;
		}
		h1 {
			font-size: 3rem;
		}
	}
	@include media-breakpoint-up(sm) {
		.overlay {
			padding: 6rem 0 4.5rem 0;
		}
		h1 {
			font-size: 3.5rem;
		}
	}
	@include media-breakpoint-up(md) {
		.overlay {
			padding: 7rem 0 6rem 0;
		}
		h1 {
			font-size: 4rem;
		}
	}
	@include media-breakpoint-up(lg) {
		.overlay {
			padding: 8rem 0 7.5rem 0;
		}		
		h1 {
			font-size: 4.5rem;
		}
	}
}
.bg-image { 
	background-size: cover; 
	background-position: center center; 
}
.overlay { 
	position: relative; 
	background-color: rgba(0,0,0,0.8); 
	height: 100%; 
	width: 100%; 
	z-index: 2; 
	padding: 9rem 0;
	text-align: center;
}
.video_wrap { 
	height: 100%; 
	width: 100%; 
	position: absolute; 
	left: 0; 
	overflow: hidden; 
	top: 0; 
	padding-bottom: 56.5%; 
	iframe { 
		height: 100%; 
		position: absolute; 
		width: 100%; 
		top: 0; 
		left: 0; 
	}
} 

body.home #content {
	padding-top: 2rem;
}

#buckets {
	padding-bottom: 1rem;
	.block {
		background-position: center center;
		background-size: cover;
		border-radius: $border-radius;
		margin-bottom: 1rem;
		&:hover {
			transform: scale(1.1);
			position: relative;
			z-index: 10;
			box-shadow: 0 0 1rem rgba(0,0,0,0.25);
		}
	}
	#bucket-schedule {
		background-image: url(../images/bg-bucket-schedule.jpg);
	}
	#bucket-workout {
		background-image: url(../images/bg-bucket-workout.jpg);
	}
	#bucket-dropin {
		background-image: url(../images/bg-bucket-dropin.jpg);
	}
}

#card-promo,
.card-workout-today {
	background-position: center center;
	background-size: cover;
	&.category-ufit {
		background-image: url(../images/bg-card-ufit.jpg);	
	}
	&.category-crossfit-santa-fe {
		background-image: url(../images/bg-card-crossfit.jpg);	
	}
	.card-header {
		a {
			@extend .text-white;
			text-transform: uppercase;
			font-weight: 900;
		}
		small {
			@extend .text-white;
			margin-top: .25rem;
			line-height: 1.25;
			text-transform: uppercase;
		}
	}
}

.card-workout {
	.card-header {
		a {
			@extend .text-white;
			text-transform: uppercase;
			font-weight: 900;
		}
		small {
			@extend .text-white;
			margin-top: .25rem;
			line-height: 1.25;
			text-transform: uppercase;
		}
	}
}

#optin {
	padding: 1rem 0 2rem 0;
	h4 {
		line-height: 3rem;
		margin: 0 0 0 0;
	}
}

#social {
	padding: 0 0 0 0;
	a {
		color: #eee;
		&:hover {
			color: #fff;
		}
	}
}

body.home .intro {
	margin-bottom: 2rem;
}