body,
nav,
header,
section,
footer,
.container {
	position: relative;
}

.section {
	padding: 4rem 0;
	&.section-white {
		background: #fff;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-gray {
		background: #f5f5f5;
		hr, .page-header {
			border-color: #eee;
		}
	}
	&.section-black {
		background: #000;
		color: #fff;
		hr, .page-header {
			border-color: rgba(0,0,0,0.1);
		}
	}
	&.section-lg {
		padding: 4rem 0;
		min-height: 100vh;
	}
}

.bg-black {
	background-color: #000; 
}

a.bg-light {
	&:hover {
		background-color: #f8f9fa !important;
	}
}

.center-vert {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.container,
.container-fluid {
	padding: 0 2rem;
}

.widget {
	margin-bottom: 2rem;
}

.card-body {
	padding: 2rem;
}

.card-img-top {
	height: auto;
}

hr {
	margin: 4rem 0;
}

.no-gutters {
	padding: 0 0 0 0;
	.row {
		margin: 0 0 0 0;
		.col {
			padding: 0 0 0 0;
		}
	}
}

h1,h2,h3,h4,h5,h6 {
	&.block {
		display: block;
		font-family: $font-family-condensed;
		font-weight: 900;
		text-transform: uppercase;
		color: $red;
		margin: 0 0 0 0;
		a {
			color: inherit;
			display: block;
			padding: 2rem;
			text-align: center;
			&:hover {
				text-decoration: none;
			}
		}
	}
	small {
		display: block;
		margin-bottom: .5rem;
		text-transform: uppercase;
		letter-spacing: .125rem;
		font-size: 50%;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		color: $body-color;
	}
}

.lead {
	font-size: 1.5rem;
	line-height: 2rem;
}

a.card,
a.media {
	color: $body-color;
	&:hover {
		text-decoration: none;
	}
	h4,.h4,h5,.h5,h6,.h6 {
		color: $red;
	}
	.media-body {
		h4,.h4,h5,.h5,h6,.h6 {
			color: $body-color;
		}
	}
}

a.d-block {
	padding: 2rem;
	&:hover {
		background: $red;
		color: #fff !important;
		transform: scale(1.1);
		position: relative;
		z-index: 10;
		box-shadow: 0 0 1rem rgba(0,0,0,0.25);
		border-radius: $border-radius;
	}
}

.date {
	width: 4rem;
	span {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		padding: .25rem .625rem;
		text-align: center;
	}
	.month {
		background: $red;
		color: #fff;
		box-shadow: .125rem .125rem 0 #ddd;
		border-radius: $border-radius-sm $border-radius-sm 0 0;
	}
	.day {
		@extend .bg-light;
		box-shadow: .125rem .125rem 0 #eee;
		font-size: 2rem;
		border-radius: 0 0 $border-radius-sm $border-radius-sm;
		&.day-light {
			@extend .bg-white;
		}
	}
}

.intro {
	p {
		&.lead {
			a {
				color: $body-color;
			}
		}
	}
}

.menu,
.nav-sub {
	@extend .nav;
	@extend .flex-column;
	.menu-item,
	.page_item {
		@extend .nav-item;
		a {
			@extend .nav-link;
		}
	}
}

.card {
	width: 100%;
	margin-bottom: 2rem !important;
}

// Bootstrap 4 breakpoints & gutter
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;

$grid-gutter-width: 32px !default;

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 1,
    md: 1,
    lg: 3,
    xl: 3
);

@each $name, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck .card {
			flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
		}
	}
}

// number of cards per line for each breakpoint
$cards-per-line-lg: (
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2
);

@each $name-lg, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck-lg .card {
			flex: 0 0 calc(#{100/map-get($cards-per-line-lg, $name-lg)}% - #{$grid-gutter-width});
		}
	}
}

.deck {
   display: flex;
   flex-wrap: wrap;
}

.deck > div[class*='col-'] {
  display: flex;
}

.row-cat {
   display: flex;
   flex-wrap: wrap;
}

.row-cat > div[class*='col-'] {
  display: flex;
}

.breadcrumb {
    position: absolute;
    top: -4rem;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    font-size: .75rem;	
}

.nav-sub {
	li {
		@extend .nav-item;
		&.current_page_item {
			a {
				font-weight: bold;
				color: $red;
				border-left: .25rem solid $red;
			}
		}
	}
	a {
		@extend .nav-link;
		border-left: .25rem solid #eee;
		margin-bottom: .25rem;
		&:hover,
		&:focus {
			border-color: #ccc;
		}
	}
}

.g-recaptcha {
	> div {
		margin: 1rem auto 0 auto;
	}
}

.rc-anchor-light.rc-anchor-normal {
	margin: 0 auto !important;
}

.gallery {
	@extend .row;
	.gallery-item {
		@extend .col-3;
		margin-bottom: 2rem;
		border: 0px none;
		border-radius: 0;
	    text-align: center;
		-webkit-transition: -webkit-transform 200ms;
		transition: transform 200ms;
		&:hover,
		&:active {
			transform: scale(1.025);
			img {
				box-shadow: 0 0 1rem rgba(33,37,41,0.2);			
			}
		}
		img {
			@extend .img-fluid;
			@extend .rounded;
			box-shadow: 0 0 .25rem rgba(33,37,41,0.1);
			-webkit-transition: -webkit-transform 200ms;
			transition: transform 200ms;
		}
		figcaption {
			padding: .5rem .1rem;
			text-align: center;
		}
	}
}
