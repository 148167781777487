.navbar-brand {
	background-image: url(../images/undisputed-fitness.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 185px 50px;
	width: 13.75rem;
	height: 5.375rem;
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	padding: 1rem;
	background-color: $red;
	display: block;
	z-index: 1000;
	border-radius: 0 0 $border-radius-sm $border-radius-sm;
	box-shadow: 0 0 16px rgba(0,0,0,0.25);
	@extend .text-hide;
}

.navbar-nav {
	text-transform: uppercase;
	.nav-item {
		&.show {
			> .nav-link {
				color: $red !important;
			}
		}
	}
}

.dropdown-menu {
	border-radius: 0 0 $border-radius $border-radius;
	border: 0px none;
	border-top: .25rem solid $red;
	font-size: .75rem;
	margin-top: .25rem;
	left: .5rem;
	.dropdown-item {
		padding: .5rem 1rem;
		&:hover {
			color: $red;
		}
	}
}

html,
body {
	overflow-x: hidden; /* Prevent scroll on narrow devices */
}

.navbar-toggler {
	border: 0px none;
	font-family: $font-family-condensed;
	font-weight: 900;
}

.navbar-dark .navbar-toggler {
	color: #eee;
	&:hover {
		color: $red;
	}
}

.navbar-collapse {
	.nav {
		margin-bottom: 1rem;
	}
	.nav-link {
		padding: .25rem 0;
		color: #eee;
		&:hover {
			color: #fff;
		}
	}	
}

.nav-social {
	@include media-breakpoint-down(xs) {
		display: none;
	}
	li {
		a {
			color: #eee;
			&:hover {
				color: $red;
			}
		}
	}
}

body.admin-bar .navbar.fixed-top {
    top: 32px;
}

.navbar-collapse {
	border-radius: $border-radius;
	text-align: center;
	.container-fluid {
		padding: 4rem 1rem 0 1rem;
	}
}