#social {
	a {
		&.d-block {
			font-size: 3rem;
		}
	}
}

#info {
	text-align: center;
	.nav {
		margin-bottom: 1rem;
	}
	.nav-link {
		padding: .25rem 0;
		color: #eee;
		&:hover {
			color: #fff;
		}
	}
	.copyright {
		display: block;
		color: #666;
		margin: 1rem auto 3rem auto;
		a {
			color: #666;
			&:hover {
				color: #fff;
			}
		}
	}
}